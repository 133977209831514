/* src/styles/components.css */
.btn-primary {
  @apply w-full py-2 bg-primary text-white rounded-md hover:bg-accent font-bold;
}

.btn-transparent {
  @apply text-primary hover:text-accent font-bold;
}

.btn-transparent-border {
  @apply w-full py-2 text-primary hover:border-accent hover:text-accent font-bold border-2 border-primary rounded-md;
}

.btn-secondary {
  @apply px-3 py-2 bg-transparent text-primary border-2 border-primary rounded-md hover:bg-[#6A1B9A33] font-medium;
}
.btn-secondary {
  @apply px-3 py-2 bg-transparent text-primary border-2 border-primary rounded-md hover:bg-[#6A1B9A33] font-medium;
}
