@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/tailwindComponents.css";

@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p {
  font-family: "Poppins", sans-serif;
}
label{
  font-family: "Poppins", sans-serif;
}
strong {
  font-family: "Poppins", sans-serif;
}
button {
  font-family: "Poppins", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Toastify__toast {
  background-color: #4e0477 !important;
}

.Toastify__progress-bar {
  color: white !important;
  font-weight: bold;
}

.Toastify__toast-theme--light.Toastify__toast--success {
  color: white !important;
  font-weight: bold;
}
.Toastify__toast-theme--light.Toastify__toast--warning {
  color: orange !important;
  font-weight: bold;
}
.Toastify__toast-theme--light.Toastify__toast--error {
  color: rgb(255, 71, 58) !important;
  font-weight: bold;
}
.Toastify__close-button {
  color: white !important;
}

.Toastify__progress-bar-theme--light.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--light.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--light.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--light.Toastify__progress-bar--error {
  color: #ffd700;
  background-color: #ffd700;
}
.react-calendar {
  border: transparent !important;
  outline: transparent !important;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  width: 95% !important;
}

.react-calendar__navigation button {
  background: transparent;
  color: #6a1b9a;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}

.react-calendar__month-view__weekdays {
  text-transform: uppercase;
  font-size: 0.9em;
  color: #6a1b9a;
}

.react-calendar__tile {
  background: #f8f9fa;
  border-radius: 6px;
  text-align: center;
  padding: 10px;
  font-size: 1em;
}

.react-calendar__tile--active {
  background: #ffd700 !important;
  color: white;
}

.react-calendar__tile--now {
  /* background: #6a1b9a !important; */
  color: #fff !important;
}

.react-calendar__tile:hover {
  background: #e2e6f3;
}
.react-calendar__month-view__days__day--weekend {
  color: inherit !important;
}

.blocked-date {
  background-color: #6a1b9a !important;
  color: white !important;
  height: 3em;
  width: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.react-calendar__navigation {
  border-bottom: 1px solid #757575;
  padding-bottom: 1px;
  margin-bottom: 1px;
}
.alice-carousel__dots {
  margin-top: 0px !important; /* Adjust this value to reduce space */
}

/* Change color on hover */
input[type="radio"]:hover {
  border-color: rgba(117, 117, 117, 0.8);
}

input[type="radio"] {
  -webkit-appearance: none; /* Remove default appearance */
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(117, 117, 117, 0.8);
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: rgba(117, 117, 117, 0.8);
}

textarea {
  resize: none;
  scrollbar-width: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Custom scrollbar hiding */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

.sub_heading {
  font-size: 1.5rem;
  color: #6a1b9a;
  font-weight: 600;
  font-family: "Poppins";
  line-height: 1.6;
}
.horizontal-scroll-container {
  display: flex;
  overflow-x: scroll;
  padding: 10px;
  cursor: grab;
  scrollbar-width: none; /* For Firefox */
}

.horizontal-scroll-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
/* Base styles for the checkbox */
.filtercheckbox input[type="checkbox"] {
  appearance: none; /* Removes default checkbox styling */
  width: 25px;
  height: 25px;
  border: 2px solid #6a1b9a; /* Purple border */
  border-radius: 4px; /* Rounded corners */
  outline: none; /* Removes focus outline */
  cursor: pointer;
  position: relative; /* For pseudo-element positioning */
  background-color: transparent; /* Transparent background */
}

.filtercheckbox input[type="checkbox"]:checked {
  border-color: #6a1b9a;
}

.filtercheckbox input[type="checkbox"]:checked::after {
  content: "";
  width: 6px;
  height: 13px;
  border: solid #6a1b9a;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}
.selectAddress input[type="radio"]:hover {
  border-color:#6A1B9A !important;
}

.selectAddress input[type="radio"] {
  -webkit-appearance: none; 
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #6A1B9A !important;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.selectAddress input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #6A1B9A !important;
}


.customRadio:hover {
  border-color: #F9D703 !important;
}

.customRadio {
  -webkit-appearance: none; 
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #F9D703 !important;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.customRadio:checked::before {
  content: "";
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #F9D703 !important;
}
.customCheckbox:hover {
  border-color: #F9D703 !important;
}

.customCheckbox {
  -webkit-appearance: none; 
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #F9D703 !important;
  border-radius: 4px; /* For a square checkbox */
  margin-right: 10px;
  display: inline-block;
  position: relative;
}

.customCheckbox:checked::before {
  content: "";
  position: absolute;
  top: 3px;
  left: 6px;
  width: 6px;
  height: 12px;
  border: solid #F9D703 !important;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg); /* Creates the checkmark */
}
